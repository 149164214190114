export const DETAIL_ADVERT_SEGMENT = '/ad';
export const DETAIL_SHELTER_SEGMENT = '/shelter';
export const DETAIL_VET_SEGMENT = '/vet';

class WebUrlPathsClass {
  static DETAIL_ADVERT = `${DETAIL_ADVERT_SEGMENT}/:id`;
  static DETAIL_SHELTER = `${DETAIL_SHELTER_SEGMENT}/:id`;
  static DETAIL_VET = `${DETAIL_VET_SEGMENT}/:id`;
  static ADMIN_PART_IMPORT = 'import';
  static ADMIN_PART_REPORTS = 'reports';
  static ADMIN_PART_VIDEOS = 'videos';
  static ADMIN_IMPORT_IMPORTED_ITEMS = 'imported-items';
  static ADMIN_IMPORT_IMPORTS = 'imports';
  static ADMIN_IMPORT_IMPORT_SOURCES = 'import-sources';
}

export default WebUrlPathsClass;

