abstract class BaseHttpQueryMapper {

  public static parseArrayFilterValueStatic(value?: string | string[]): string[] | undefined {
    if (typeof value === 'string') {
      return [value];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return undefined;
  }

  protected parseArrayFilterValue(value?: string | string[]): string[] | undefined {
    return BaseHttpQueryMapper.parseArrayFilterValueStatic(value);
  }

  protected parseTaxonomyArrayFilterValue(taxonomy: string[], value?: string | string[]): string[] | undefined {
    if (typeof value === 'string') {
      return taxonomy.includes(value) ? [value] : undefined;
    }

    if (Array.isArray(value)) {
      const result = value.filter(v => taxonomy.includes(v));

      return result.length ? result : undefined;
    }

    return undefined;
  }
}

export default BaseHttpQueryMapper;
