import type { Search } from 'react-router-dom';
import BaseAppCommonConstants from '../../../constants/BaseAppCommonConstants';
import BaseFilterCodeEnum from '../../../constants/enums/BaseFilterCodeEnum';
import type { DeepReadonly } from '../../../constants/types/UtilityTypes';
import type { BaseEntitiesQuery } from '../../../models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../models/queries/base/BaseEntitiesQueryFilters';
import type { BaseEntitiesUrlQuery } from '../../../models/queries/base/BaseEntitiesUrlQuery';
import type { PaginationModel } from '../../../models/system/pagination/PaginationModel';
import QueryStringHelper from '../../QueryStringHelper';
import formatDateSortable from '../../date/formatDateSortable';
import type { IEntitiesUrlQueryMapper } from '../interfaces/IEntitiesUrlQueryMapper';
import BaseHttpQueryMapper from './BaseHttpQueryMapper';

const dateRegex = /(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/;

abstract class BaseEntitiesUrlQueryMapper<
  TUrlQuery extends BaseEntitiesUrlQuery = BaseEntitiesUrlQuery,
  TQueryFilters extends BaseEntitiesQueryFilters = BaseEntitiesQueryFilters,
  TQuery extends BaseEntitiesQuery<TQueryFilters> = BaseEntitiesQuery<TQueryFilters>
> extends BaseHttpQueryMapper implements IEntitiesUrlQueryMapper<TQuery> {

  public abstract mapUrlQueryToQuery(urlQuery: TUrlQuery): DeepReadonly<TQuery>;

  public abstract mapQueryToUrlQuery(query: TQuery): TUrlQuery;

  protected abstract mapUrlQueryToQueryFilters(urlQuery: TUrlQuery): TQueryFilters;

  protected abstract createEmptyQuery(): TQuery;

  private search?: Search;

  private query?: DeepReadonly<TQuery>;

  public parse(search: Search): DeepReadonly<TQuery> {
    if (search !== this.search || !this.query) {
      this.search = search;
      this.query = search === ''
        ? this.createEmptyQuery()
        : this.mapUrlQueryToQuery(QueryStringHelper.parse(search) as TUrlQuery);
    }

    return this.query;
  }

  public stringifyForURL(query: DeepReadonly<TQuery> | undefined): string {
    if (query) {
      const urlQuery = this.mapQueryToUrlQuery(query);
      const search = QueryStringHelper.stringify(urlQuery);

      return search;
    } else {
      return '';
    }
  }

  protected mapUrlQueryToQueryIntrinsic(urlQuery: TUrlQuery): DeepReadonly<BaseEntitiesQuery<TQueryFilters>> {
    const filters = this.mapUrlQueryToQueryFilters(urlQuery);
    const pagination = this.mapUrlQueryToQueryPagination(urlQuery);

    const query: BaseEntitiesQuery<TQueryFilters> = {};

    if (filters && Object.keys(filters).length > 0) {
      query.filters = filters;
    }

    if (pagination && Object.keys(pagination).length > 0) {
      query.pagination = pagination;
    }

    return query;
  }

  protected mapUrlQueryToQueryFiltersIntrinsic(urlQuery: TUrlQuery): BaseEntitiesQueryFilters {
    const queryFilters: BaseEntitiesQueryFilters = {};

    const createdFrom = this.parseDateUrlQueryValue(urlQuery[BaseFilterCodeEnum.CreatedFrom]);
    const createdTo = this.parseDateUrlQueryValue(urlQuery[BaseFilterCodeEnum.CreatedTo]);
    const query = this.parseTextUrlQueryValue(urlQuery.query);
    const id = this.parseTextUrlQueryValue(urlQuery.id);

    if (createdFrom !== undefined) {
      queryFilters.createdFrom = createdFrom;
    }

    if (createdTo !== undefined) {
      queryFilters.createdTo = createdTo;
    }

    if (query !== undefined) {
      queryFilters.query = query;
    }

    if (id !== undefined) {
      queryFilters.id = id;
    }

    return queryFilters;
  }

  protected mapQueryToUrlQueryIntrinsic(query: DeepReadonly<TQuery>): BaseEntitiesUrlQuery {
    const urlQuery: BaseEntitiesUrlQuery = {};

    const queryFilters = query.filters;

    if (queryFilters) {
      urlQuery.id = queryFilters.id;
      urlQuery[BaseFilterCodeEnum.CreatedFrom] = this.parseDateToUrlQuery(queryFilters.createdFrom);
      urlQuery[BaseFilterCodeEnum.CreatedTo] = this.parseDateToUrlQuery(queryFilters.createdTo);
      urlQuery.query = queryFilters.query;
    }

    // TODO Move pagination as last parameters

    if (query.pagination?.page) {
      urlQuery.page = (query.pagination.page + 1).toString();
    }

    if (query.pagination?.size) {
      urlQuery.size = query.pagination.size.toString();
    }

    if (query.pagination?.sort) {
      urlQuery.sort = query.pagination.sort;
    }

    return urlQuery;
  }

  protected getUrlQueryToQueryPaginationParams(urlQuery: TUrlQuery): { page: string | undefined; size: string | undefined; sort: string | undefined; } {
    const page = urlQuery.page;
    const size = urlQuery.size;
    const sort = urlQuery.sort;

    return {
      page,
      size,
      sort,
    };
  }

  protected mapUrlQueryToQueryPagination(urlQuery: TUrlQuery): PaginationModel | undefined {
    const { page: urlPage, size: urlSize, sort: urlSort, } = this.getUrlQueryToQueryPaginationParams(urlQuery);

    const parsedPage = Math.max(0, (parseInt(urlPage as string, 10) - 1) || 0);
    const parsedSize = parseInt(urlSize as string, 10) || BaseAppCommonConstants.getInstance().PAGINATION_DEFAULT_SIZE;
    const parsedSort = urlSort;

    const page = parsedPage === 0 ? undefined : parsedPage;
    const size = parsedSize === BaseAppCommonConstants.getInstance().PAGINATION_DEFAULT_SIZE ? undefined : parsedSize;
    const sort = parsedSort === '' || typeof parsedSort !== 'string'
      ? undefined
      : parsedSort;

    const pagination: PaginationModel | undefined = page !== undefined || size !== undefined || sort !== undefined
      ? {
        page: page,
        size: size,
        sort: sort,
      }
      : undefined;

    return pagination;
  }

  protected parseTextUrlQueryValue = (value?: string): string | undefined => {
    const trimmed = value?.trim();

    return trimmed
      ? trimmed
      : undefined;
  };

  protected parseDateUrlQueryValue = (x: string | undefined): Date | undefined => {
    if (x && typeof x === 'string') {
      const match = dateRegex.exec(x);

      if (match && match.groups) {
        const year = match.groups['year'];
        const month = match.groups['month'];
        const day = match.groups['day'];

        if (year && month && day) {
          const yearInt = parseInt(year);
          const monthInt = parseInt(month);
          const dayInt = parseInt(day);

          if (isFinite(yearInt) && isFinite(monthInt) && isFinite(dayInt)) {
            const dateRaw = new Date(yearInt, monthInt - 1, dayInt, 0, 0, 0);

            return dateRaw;
          }
        }
      }
    }

    return undefined;
  };

  protected parseDateToUrlQuery = (date?: DeepReadonly<Date>): string | undefined => {
    return date
      ? formatDateSortable(date)
      : undefined;
  };
}

export default BaseEntitiesUrlQueryMapper;
